.TextField input:-webkit-autofill,
.TextField input:-webkit-autofill:hover, 
.TextField input:-webkit-autofill:focus
.TextField textarea:-webkit-autofill,
.TextField textarea:-webkit-autofill:hover
.TextField textarea:-webkit-autofill:focus,
.TextField select:-webkit-autofill,
.TextField select:-webkit-autofill:hover,
.TextField select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}
.ReactMDEEditor .mde-header .mde-tabs {
  display: none;
}
.Slug input:-webkit-autofill,
.Slug input:-webkit-autofill:hover, 
.Slug input:-webkit-autofill:focus
.Slug textarea:-webkit-autofill,
.Slug textarea:-webkit-autofill:hover
.Slug textarea:-webkit-autofill:focus,
.Slug select:-webkit-autofill,
.Slug select:-webkit-autofill:hover,
.Slug select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

