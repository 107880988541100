.TextField input:-webkit-autofill,
.TextField input:-webkit-autofill:hover, 
.TextField input:-webkit-autofill:focus
.TextField textarea:-webkit-autofill,
.TextField textarea:-webkit-autofill:hover
.TextField textarea:-webkit-autofill:focus,
.TextField select:-webkit-autofill,
.TextField select:-webkit-autofill:hover,
.TextField select:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
}